import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@oup/shared-front-end/src/components/Button/Button.js';
import useKeydown from '@oup/shared-front-end/src/hooks/useKeydown';
import { ICON_CLOSE } from '@oup/shared-front-end/src/svg/oup/index.js';
import styles from './ProductDetailsModal.scss';
import { hideProductDetailsModal } from '../../../redux/actions/ngiContentSelectorActions.js';
import { isLocal } from '../../../globals/envSettings';

function ProductDetailsModal() {
  const dispatch = useDispatch();
  const addFallbackCover = ev => {
    if (isLocal()) {
      return;
    }
    ev.target.src = '/media/feedback/book-cover.png';
  };

  const { productDetailsModal } = useSelector(state => state.ngiContentSelector);
  const handleOnCloseModal = () => {
    dispatch(hideProductDetailsModal());
  };

  useKeydown('Escape', handleOnCloseModal);

  return (
    <div className={styles.container}>
      <dialog className={styles.modal} open>
        <div className={styles.header}>
          <div className={styles.wrapper_top}>
            <Button
              variant="transparent"
              text="Close"
              size="small"
              icon={{
                placement: 'right',
                component: <ICON_CLOSE />
              }}
              onClick={handleOnCloseModal}
            />
            <section className={styles.cover}>
              <div className={styles.broken}>
                <img src={productDetailsModal.product.cover} alt="" onError={addFallbackCover} />
              </div>
              <div>
                <h2 className={styles.title}>{productDetailsModal.product.title || '-'}</h2>
              </div>
            </section>
          </div>
        </div>
        <div className={styles.wrapper_bottom}>
          {productDetailsModal.product.series && (
            <p className={styles.border}>
              <span>Course or Series: </span>
              {productDetailsModal.product.series || '-'}
            </p>
          )}
          <p className={styles.border}>
            <span>ISBN: </span>
            {productDetailsModal.product.isbn || productDetailsModal.product.isbnLaunch || '-'}
          </p>
          <p className={styles.border}>
            <span>Author(s): </span>
            {productDetailsModal.product.author || '-'}
          </p>
          <p className={styles.border}>
            <span>Level: </span>
            {productDetailsModal.product.cefr_level || productDetailsModal.product.level || '-'}
          </p>
          <p className={styles.border}>{productDetailsModal.product.description || ''}</p>
        </div>
      </dialog>
    </div>
  );
}

export default ProductDetailsModal;
